/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title }) {
	const { site, image } = useStaticQuery(
	graphql`
	query {
		site {
			siteMetadata {
				title
				description
				author
				siteUrl
			}
		},
		image: file(relativePath: { eq: "A736416D-1265-43E8-BD43-EA3061A13A79.png" }) {
			base
		}
	}
	`
  )
// console.log(site)
// console.log(image)
	const metaDescription = description || site.siteMetadata.description

	return (
	<Helmet
		htmlAttributes={{
		lang,
		}}
		title={title}
		titleTemplate={`%s | ${site.siteMetadata.title}`}
		meta={[
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: title,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: 'og:image',
			content: site.siteMetadata.siteUrl+'/'+image.base,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			name: `twitter:card`,
			content: `summary`,
		},
		{ name: 'twitter:site', content: '@toddaa' },
		{
			name: `twitter:creator`,
			content: site.siteMetadata.author,
		},
		{
			name: `twitter:title`,
			content: title,
		},
		{
			name: `twitter:description`,
			content: metaDescription,
		},
		]
		.concat(
			keywords.length > 0
			? {
				name: `keywords`,
				content: keywords.join(`, `),
				}
			: []
		)
		.concat(meta)}
	/>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
